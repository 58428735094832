import i18next from "i18next";

const weekdays_sorter = {
  "Mo": 1,
  "Tu": 2, "Di": 2,
  "Mi": 3, "We": 3,
  "Th": 4, "Do": 4,
  "Fr": 5,
  "Sa": 6,
  "Su": 7, "So": 7
}

const parseWorkingDays = (workingDays) =>{
  return workingDays.map(
      day => i18next.t(`shipping_profile_list.short_${day.toLowerCase()}`))
  .sort(function sortByDay(a, b) {
    return weekdays_sorter[a] - weekdays_sorter[b];
  })
  .join(", ");
}

export default parseWorkingDays;
