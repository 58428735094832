import axios from 'axios';

const createOrUpdateShippingProfile =  (shippingProfileName, shippingMethod, defaultProcessingTime, transportTime, cutOffTime, workingDays, isUpdate, shippingProfileId) =>  {
  let days = Object.keys(workingDays).filter(workingDay => workingDays[workingDay]);
  let payload = {
    "shippingProfileName": shippingProfileName,
    "workingDays": days,
    "orderCutoff": cutOffTime,
    "deliveryType": shippingMethod,
    "transportTime": parseInt(transportTime),
    "defaultProcessingTime": parseInt(defaultProcessingTime)
  };
  if (isUpdate) {
  return axios.put(`/partner-capability/v1/shipping-profiles/${shippingProfileId}`, payload, {headers: {'Content-Type': 'application/json' } })
  } else {
    return axios.post("/partner-capability/v1/shipping-profiles", payload, {headers: {'Content-Type': 'application/json' } })
  }
}

export const fetchAllShippingProfiles = () => { return axios.get("/partner-capability/v1/shipping-profiles", {headers: {'Content-Type': 'application/json' } })}
export const deleteShippingProfile = (shippingProfileId) => { return axios.delete(`/partner-capability/v1/shipping-profiles/${shippingProfileId}`) }

export default createOrUpdateShippingProfile;
