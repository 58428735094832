import React, {useState, useEffect} from 'react';
import {
  B2bDropdown,
  B2bGrid,
  B2bGridCol,
  B2bGridRow,
  B2bInput,
  B2bCheckboxGroup,
  B2bSeparator,
  B2bButton,
  B2bAlert,
  B2bModal, B2bParagraph, B2bAnchor
} from "@otto-de/b2b-react-components";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";
import createOrUpdateShippingProfile, {
  deleteShippingProfile
} from "../api/backend";
import validateTransportTime, {
  validateCutoffTime, validateDefaultProcessingTime,
  validateProfileName
} from "../utils/validations";
import LabelWithToolTip from "./LabelWithToolTip";
import i18next from 'i18next';

function ShippingProfileForm() {
  let navigate = useNavigate();
  const {t} = useTranslation();
  const {state} = useLocation();
  const isUpdateFlow = state != null && state.profile != null;
  const fetchWorkingDays = () => {
    const workingDaysMap = new Map();
    state.profile.workingDays.forEach(
        (day) => workingDaysMap.set(day.toLowerCase(), true))
    return Object.fromEntries(workingDaysMap);
  }

  const [shippingProfileName, setShippingProfileName] = useState(
      isUpdateFlow ? state.profile.shippingProfileName : "")
  const [shippingMethod, setShippingMethod] = useState(
      isUpdateFlow ? state.profile.deliveryType : "")
  const [defaultProcessingTime, setDefaultProcessingTime] = useState(
      isUpdateFlow ? state.profile.defaultProcessingTime : "")
  const [transportTime, setTransportTime] = useState(
      isUpdateFlow ? state.profile.transportTime : "")
  const [cutOffTime, setCutOffTime] = useState(
      isUpdateFlow ? state.profile.orderCutoff : "")
  const [workingDays] = useState(isUpdateFlow ? fetchWorkingDays : {
    monday: true,
    tuesday: true,
    wednesday: true,
    thursday: true,
    friday: true
  })
  const [isDefaultProcessingTimeValid, setIsDefaultProcessingTimeValid] = useState(
      true)
  const [isTransportTimeValid, setIsTransportTimeValid] = useState(true)
  const [isShippingProfileNameValid, setIsShippingProfileNameValid] = useState(
      true)
  const [shippingProfileNameErrorMessage, setShippingProfileNameErrorMessage] = useState(
      "")
  const [processingTimeErrorMessage, setProcessingTimeErrorMessage] = useState(
      "");
  const [transportTimeErrorMessage, setTransportTimeErrorMessage] = useState(
      "");
  const [cutoffTimeErrorMessage, setCutoffTimeErrorMessage] = useState("");
  const [isCutoffTimeValid, setIsCutoffTimeValid] = useState(true)
  const [isWorkingDaysValid, setIsWorkingDaysValid] = useState(true)
  const [isShippingMethodValid, setIsShippingMethodValid] = useState(true)
  const [shouldSubmitEnabled, setShouldSubmitEnabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [backendErrorMessages, setBackendErrorMessages] = useState([])
  const [confirmDeletion, setConfirmDeletion] = useState(false)
  const [showWorkingDaysWarning, setShowWorkingDaysWarning] = useState(false)

  useEffect(() => {
    setShouldSubmitEnabled(shippingProfileName && isShippingProfileNameValid
        && defaultProcessingTime && isDefaultProcessingTimeValid
        && cutOffTime && isCutoffTimeValid
        && transportTime && isTransportTimeValid
        && shippingMethod)
  }, [cutOffTime, defaultProcessingTime, isCutoffTimeValid,
    isDefaultProcessingTimeValid, isShippingProfileNameValid,
    isTransportTimeValid, shippingMethod, shippingProfileName, transportTime]);

  const validateTransport = () => {
    validateTransportTime(transportTime, setIsTransportTimeValid,
        setTransportTimeErrorMessage);
  }

  const validateShippingProfileName = () => {
    validateProfileName(shippingProfileName.trim(),
        setIsShippingProfileNameValid, setShippingProfileNameErrorMessage);
  }

  const validateCutoff = () => {
    validateCutoffTime(cutOffTime, setIsCutoffTimeValid,
        setCutoffTimeErrorMessage);
  }

  const validateDefaultProcessing = () => {
    validateDefaultProcessingTime(defaultProcessingTime,
        setIsDefaultProcessingTimeValid, setProcessingTimeErrorMessage);
  }

  const updateProfileName = (e) => {
    setShippingProfileName(e.detail.value.trim());
  }

  const updateShippingMethod = (e) => {
    setShippingMethod(e.detail);
  }

  const updateDefaultProcessingTime = (e) => {
    setDefaultProcessingTime(e.detail.value);
  }

  const updateTransportTime = (e) => {
    setTransportTime(e.detail.value);
  }

  const updateCutOffTime = (e) => {
    setCutOffTime(e.detail.value);
  }

  const updateWorkingDays = (e) => {
    workingDays[e.detail.value] = e.detail.checked
    Object.keys(workingDays).filter(
        workingDay => workingDays[workingDay]).length > 0 ?
        setIsWorkingDaysValid(true) : setIsWorkingDaysValid(false);
  }

  const onCancel = () => {
    let path = `/`;
    navigate(path)
  }

  const onDelete = () => {
    setConfirmDeletion(true)
  }

  const unsetConfirmDeletion = () => {
    setConfirmDeletion(false)
  }

  const onConfirmDeletion = () => {
    deleteShippingProfile(state.profile.shippingProfileId)
    .then(redirectOnDeletion).catch(error => {
      setConfirmDeletion(false)
      handleErrors(error)
    })
  }

  const unsetWarning = (e) => {
    e.preventDefault();
    setShowWorkingDaysWarning(false);
  }

  const redirectOnDeletion = () => {
    setLoading(false);
    let path = "/?delete=success"
    navigate(path, {state: shippingProfileName})
  }

  const redirectOnSuccess = () => {
    setLoading(false);
    let path = isUpdateFlow ? "/?update=success" : "/?create=success"
    navigate(path, {state: shippingProfileName})
  }

  const validateWorkingDays = () => {
    return Object.keys(workingDays).filter(
        workingDay => workingDays[workingDay]).length > 0;
  }

  const validateShippingMethod = () => {
    return shippingMethod !== ""
  }

  const handleErrors = (errors) => {
    setLoading(false);
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
    errors.response.data.validationErrors?.map(error => {
      if (i18next.exists(
          "shipping_profile_form.error_messages." + error.logref)) {
        setBackendErrorMessages(
            t("shipping_profile_form.error_messages." + error.logref))
      } else {
        setBackendErrorMessages(error.detail)
      }
      return backendErrorMessages
    });
  }

  const save = () => {
    setLoading(true);
    let shippingProfileId = isUpdateFlow ? state.profile.shippingProfileId
        : null
    createOrUpdateShippingProfile(shippingProfileName, shippingMethod,
        defaultProcessingTime, transportTime, cutOffTime, workingDays,
        isUpdateFlow, shippingProfileId)
    .then(redirectOnSuccess).catch(error => {
      handleErrors(error)
    });
  }

  const onSubmit = () => {
    let isWorkingDaysValid = validateWorkingDays();
    if (!isWorkingDaysValid) {
      setIsWorkingDaysValid(false);
      return;
    }
    if (!validateShippingMethod()) {
      setIsShippingMethodValid(false);
      return;
    }

    if ((parseInt(defaultProcessingTime) + parseInt(transportTime)) > 72) {
      setShowWorkingDaysWarning(true);
      return;
    }
    save();
  }

  const getShippingMethodHint = () => {
    return (
        <span className="shipping_method_hint">
          {t("shipping_profile_form.basic_information.shipping_method.hint")}
          <B2bAnchor
              href="https://account.otto.market/s/article/Versandprofile">{t(
              "shipping_profile_form.basic_information.shipping_method.hint_link")}</B2bAnchor>
        </span>
    )
  }

  const getShippingProfileId = () => {
    return (
        isUpdateFlow && <span className="shipping_method_hint">
                          {t("shipping_profile_form.basic_information.shipping_profile_id")}: {state.profile.shippingProfileId}
                      </span>
    )
  }

  const getShippingPreparationDescription = () => {
    return (
        <p>
          {t("shipping_profile_form.shipping_preparation.description")}
          <B2bAnchor
              href="https://account.otto.market/s/article/Versandprofile">{t(
              "shipping_profile_form.shipping_preparation.description_link")}</B2bAnchor>
        </p>)
  }

  return (
      <div>
        <B2bAlert opened={backendErrorMessages.length > 0} type="error"
                  data-testid="backend_validation_notification"
                  size="large">{backendErrorMessages}</B2bAlert>
        <B2bGrid margin={0}>
          <B2bGridRow row-gap="30">
            <B2bGridCol span="5">
              <B2bParagraph weight="bold" className="label_text">{t(
                  "shipping_profile_form.basic_information.name")}</B2bParagraph>
              <B2bParagraph className="label_text">{t(
                  "shipping_profile_form.basic_information.description")}</B2bParagraph>
            </B2bGridCol>
            <B2bGridCol span="7">
              <B2bGrid margin={0}>
                <B2bGridRow row-gap="0">
                  <div className="shipping_preparation_time">
                    <B2bInput class="shipping_profile"
                              data-testid="shipping_profile_name" label={t(
                        "shipping_profile_form.basic_information.profile_name")}
                              type="text" invalid={!isShippingProfileNameValid}
                              error={shippingProfileNameErrorMessage} hint={t(
                        "shipping_profile_form.basic_information.profile_name_hint")}
                              value={shippingProfileName}
                              onB2b-blur={validateShippingProfileName}
                              onB2b-input={updateProfileName}/>
                    {getShippingProfileId()}
                  </div>
                </B2bGridRow>
                <B2bGridRow>
                  <div className="shipping_preparation_time">
                    <B2bDropdown className="shipping_method"
                                 disabled={isUpdateFlow}
                                 data-testid="shipping_method" label={t(
                        "shipping_profile_form.basic_information.shipping_method.name")}
                                 name="type" invalid={!isShippingMethodValid}
                                 error={t(
                                     "shipping_profile_form.basic_information.shipping_method.error")}
                                 value={shippingMethod}
                                 onB2b-change={updateShippingMethod}>
                      <option value="">{t(
                          "shipping_profile_form.basic_information.shipping_method.DEFAULT")}</option>
                      <option value="PARCEL" selected={isUpdateFlow
                          ? state.profile.deliveryType === "PARCEL" : false}>{t(
                          "shipping_profile_form.basic_information.shipping_method.PARCEL")}</option>
                      <option value="FORWARDER_PREFERREDLOCATION"
                              selected={isUpdateFlow
                                  ? state.profile.deliveryType
                                  === "FORWARDER_PREFERREDLOCATION" : false}>{t(
                          "shipping_profile_form.basic_information.shipping_method.FORWARDER_PREFERREDLOCATION")}</option>
                      <option value="FORWARDER_CURBSIDE" selected={isUpdateFlow
                          ? state.profile.deliveryType === "FORWARDER_CURBSIDE"
                          : false}>{t(
                          "shipping_profile_form.basic_information.shipping_method.FORWARDER_CURBSIDE")}</option>
                      <option value="FORWARDER_HEAVYDUTY" selected={isUpdateFlow
                          ? state.profile.deliveryType === "FORWARDER_HEAVYDUTY"
                          : false}>{t(
                          "shipping_profile_form.basic_information.shipping_method.FORWARDER_HEAVYDUTY")}</option>
                    </B2bDropdown>
                    {getShippingMethodHint()}
                  </div>
                </B2bGridRow>
              </B2bGrid>
            </B2bGridCol>
          </B2bGridRow>
          <B2bGridRow row-gap="30">
            <B2bGridCol span="5">
              <B2bParagraph weight="bold" className="label_text">{t(
                  "shipping_profile_form.shipping_preparation.name")}</B2bParagraph>
              <B2bParagraph
                  className="label_text">{getShippingPreparationDescription()}</B2bParagraph>
            </B2bGridCol>
            <B2bGridCol span="7">
              <B2bGrid margin={0}>
                <B2bGridRow row-gap="0">
                  <div className="shipping_preparation_time">
                    <LabelWithToolTip
                        labelText={t(
                            "shipping_profile_form.shipping_preparation.standard_processing_time.name")}
                        toolTipText={t(
                            "shipping_profile_form.shipping_preparation.standard_processing_time.info")}
                    />
                    <B2bInput className="shipping_preparation"
                              data-testid="processing_time"
                              error={processingTimeErrorMessage}
                              invalid={!isDefaultProcessingTimeValid} hint={t(
                        "shipping_profile_form.shipping_preparation.standard_processing_time.hint")}
                              value={defaultProcessingTime}
                              onB2b-input={updateDefaultProcessingTime}
                              onB2b-blur={validateDefaultProcessing}
                              input-text-align="right">
                      <span slot="end">{t(
                          "shipping_profile_form.shipping_preparation.standard_processing_time.days")}</span>
                    </B2bInput>
                  </div>
                </B2bGridRow>
                <B2bGridRow>
                  <div className="shipping_preparation_time">
                    <LabelWithToolTip
                        labelText={t(
                            "shipping_profile_form.shipping_preparation.cutOff_time.name")}
                        toolTipText={t(
                            "shipping_profile_form.shipping_preparation.cutOff_time.info")}
                    />
                    <B2bInput className="shipping_preparation"
                              data-testid="cutoff_time"
                              invalid={!isCutoffTimeValid}
                              error={cutoffTimeErrorMessage} hint={t(
                        "shipping_profile_form.shipping_preparation.cutOff_time.hint")}
                              placeholder={"00:00"} value={cutOffTime}
                              onB2b-blur={validateCutoff}
                              onB2b-input={updateCutOffTime}/>
                  </div>
                </B2bGridRow>
                <B2bGridRow>
                  <div className="working-days">
                    <LabelWithToolTip
                        labelText={t(
                            "shipping_profile_form.shipping_preparation.working_days.name")}
                        toolTipText={t(
                            "shipping_profile_form.shipping_preparation.working_days.info")}
                    />
                    <B2bCheckboxGroup invalid={!isWorkingDaysValid} error={t(
                        "shipping_profile_form.shipping_preparation.working_days.error")}
                                      alignment="horizontal"
                                      onB2b-group-change={updateWorkingDays}>
                      <b2b-checkbox data-testid="monday" label={t(
                          "shipping_profile_form.shipping_preparation.working_days.monday")}
                                    class="Working-days-checkbox"
                                    value={"monday"}
                                    checked={workingDays.monday}/>
                      <b2b-checkbox label={t(
                          "shipping_profile_form.shipping_preparation.working_days.tuesday")}
                                    class="Working-days-checkbox"
                                    value={"tuesday"}
                                    checked={workingDays.tuesday}/>
                      <b2b-checkbox label={t(
                          "shipping_profile_form.shipping_preparation.working_days.wednesday")}
                                    class="Working-days-checkbox"
                                    value={"wednesday"}
                                    checked={workingDays.wednesday}/>
                      <b2b-checkbox label={t(
                          "shipping_profile_form.shipping_preparation.working_days.thursday")}
                                    class="Working-days-checkbox"
                                    value={"thursday"}
                                    checked={workingDays.thursday}/>
                      <b2b-checkbox label={t(
                          "shipping_profile_form.shipping_preparation.working_days.friday")}
                                    class="Working-days-checkbox"
                                    value={"friday"}
                                    checked={workingDays.friday}/>
                      <b2b-checkbox label={t(
                          "shipping_profile_form.shipping_preparation.working_days.saturday")}
                                    class="Working-days-checkbox"
                                    value={"saturday"}
                                    checked={workingDays.saturday}/>
                      <b2b-checkbox label={t(
                          "shipping_profile_form.shipping_preparation.working_days.sunday")}
                                    class="Working-days-checkbox"
                                    value={"sunday"}
                                    checked={workingDays.sunday}/>
                    </B2bCheckboxGroup>
                  </div>
                </B2bGridRow>
              </B2bGrid>
            </B2bGridCol>
          </B2bGridRow>
          <B2bGridRow row-gap="30">
            <B2bGridCol span="5">
              <B2bParagraph weight="bold" className="label_text">{t(
                  "shipping_profile_form.shipment.name")}</B2bParagraph>
              <B2bParagraph className="label_text">{t(
                  "shipping_profile_form.shipment.description")}</B2bParagraph>
            </B2bGridCol>
            <B2bGridCol span="7">
              <div className="shipping_preparation_time">
                <LabelWithToolTip
                    labelText={t(
                        "shipping_profile_form.shipment.transport_time.name")}
                    toolTipText={t(
                        "shipping_profile_form.shipment.transport_time.info")}
                />
                <B2bInput className="shipping_preparation"
                          data-testid="transport_time"
                          invalid={!isTransportTimeValid}
                          error={transportTimeErrorMessage} hint={t(
                    "shipping_profile_form.shipment.transport_time.hint")}
                          value={transportTime} onB2b-blur={validateTransport}
                          onB2b-input={updateTransportTime}
                          input-text-align="right">
                  <span slot="end">{t(
                      "shipping_profile_form.shipment.transport_time.days")}</span>
                </B2bInput>
              </div>
            </B2bGridCol>
          </B2bGridRow>
        </B2bGrid>
        <B2bSeparator/>
        <div className="actions">
          <B2bButton variant="secondary" class="form_button left_buttons"
                     width="custom" onClick={onCancel}>{t(
              "shipping_profile_form.cancel")}</B2bButton>
          {isUpdateFlow &&
              <div className="actions">
                <B2bButton data-testid="delete-button"
                           disabled={!state.shouldAllowDeletion}
                           variant="secondary" class="delete_button"
                           width="custom" onClick={onDelete}>{t(
                    "shipping_profile_form.delete_profile.name")}</B2bButton>
                <B2bModal opened={confirmDeletion} variant="default" heading={t(
                    "shipping_profile_form.delete_profile.name")}
                          backdropDismiss="true" escDismiss="true"
                          onB2b-close={unsetConfirmDeletion}>
                  <div>{t("shipping_profile_form.delete_profile.info_1")}
                    <b>{shippingProfileName}</b> {t(
                        "shipping_profile_form.delete_profile.info_2")}</div>
                  <B2bButton slot="footer-right"
                             onClick={unsetConfirmDeletion}>{t(
                      "shipping_profile_form.delete_profile.cancel")}</B2bButton>
                  <B2bButton slot="footer-right" variant="primary"
                             onClick={onConfirmDeletion}>{t(
                      "shipping_profile_form.delete_profile.delete")}</B2bButton>
                </B2bModal>
              </div>
          }
          <B2bButton variant="primary" disabled={!shouldSubmitEnabled}
                     class="form_button right_buttons" type="submit"
                     width="custom" data-testid="submit_button"
                     loading={loading} onClick={onSubmit}>{t(
              "shipping_profile_form.submit")}</B2bButton>
          <B2bModal opened={showWorkingDaysWarning} variant="default" heading={t(
              "shipping_profile_form.working_days_warning.name")}
                    backdropDismiss="true" escDismiss="true"
                    data-testid="warning_modal"
                    onB2b-close={unsetWarning}>
            <span className="label_text">{t("shipping_profile_form.working_days_warning.info_1")}</span>
            <span className="label_text label_bold_text">{t("shipping_profile_form.working_days_warning.info_2")}</span>
            <span className="label_text">{t("shipping_profile_form.working_days_warning.info_3")}</span>
            <B2bButton slot="footer-right" data-testid="warning_continue_button"
                       onClick={unsetWarning}>{t(
                "shipping_profile_form.working_days_warning.continue")}</B2bButton>
            <B2bButton slot="footer-right" variant="secondary"
                       data-testid="warning_save_button"
                       onClick={save}>{t(
                "shipping_profile_form.working_days_warning.save")}</B2bButton>
          </B2bModal>
        </div>
      </div>
  );
}

export default ShippingProfileForm;
