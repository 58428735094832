import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import de from './de.json'
import en from './en.json'

i18n
  .use(initReactI18next)
  .init({
    fallbackLng: "de",
    debug: true,
    interpolation: {
      escapeValue: false,  // react already safes from xss
    },
    resources: {
      de,
      en
    },
});

const userLang = navigator.language || navigator.userLanguage;
i18n.changeLanguage(userLang);

export default i18n;