import {
  B2bIcon,
  B2bRoundedIcon,
  B2bTooltip
} from "@otto-de/b2b-react-components";

function LabelWithToolTip({labelText, toolTipText}) {
  return (
      <div style={{display: "flex"}}>
        <label className="tooltip_label">{labelText}</label>
        <B2bTooltip position="right" trigger="hover" content={toolTipText}>
          <B2bRoundedIcon color="transparent">
            <B2bIcon slot="icon" icon="b2b_icon-info"/>
          </B2bRoundedIcon>
        </B2bTooltip>
      </div>
  );
}

export default LabelWithToolTip;
