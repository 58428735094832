import i18next from 'i18next';

const constants = {
   profileNameLengthExceededMessage : i18next.t("shipping_profile_form.basic_information.profile_name_error_length"),
   profileNameIsMandatoryMessage : i18next.t("shipping_profile_form.basic_information.profile_name_error_empty"),
   timeNotInRangeMessage : i18next.t("shipping_profile_form.shipping_preparation.standard_processing_time.error_range"),
   timeIsMandatoryMessage : i18next.t("shipping_profile_form.shipping_preparation.standard_processing_time.error_blank"),
   timeUnSupportedMessage : i18next.t("shipping_profile_form.shipping_preparation.standard_processing_time.error_unsupported"),
   cutOffTimeInvalidFormatMessage : i18next.t("shipping_profile_form.shipping_preparation.cutOff_time.error_invalid"),
   cutOffTimeMandatoryMessage : i18next.t("shipping_profile_form.shipping_preparation.cutOff_time.error_blank")
}

export default constants;
