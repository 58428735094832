import {
  B2bAlert, B2bButton,
  B2bIcon,
  B2bScrollableContainer, B2bSeparator,
  B2bSpinner,
  B2bTable,
  B2bTableCell,
  B2bTableHeader,
  B2bTableRow,
  B2bTableRowgroup
} from "@otto-de/b2b-react-components";
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {fetchAllShippingProfiles} from "../api/backend";
import {useNavigate} from "react-router-dom";
import parseWorkingDays from "../utils/utils";

function ShippingProfileList() {
  const [shippingProfiles, setShippingProfiles] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [sortValue, setSortValue] = useState("not-sorted")

  let navigate = useNavigate();
  const {t} = useTranslation();

  useEffect(() => {
    fetchAllShippingProfiles()
    .then(response => {
      setShippingProfiles(response.data.results)
      setError(false)
      setLoading(false)
    })
    .catch(() => {
      setError(true)
      setLoading(false)
    })
  }, []);

  const handleClick = (profile) => {
    let path = `/create-shipping-profile/${profile.shippingProfileId}`;
    navigate(path, {state: {profile:profile, shouldAllowDeletion:shippingProfiles.length>1}} )
  }

  const routeChange = () =>{
    let path = `/create-shipping-profile`;
    navigate(path);
  }

  const onSortChange = (event) => {
    if (event.detail.sortDirection === 'ascending') {
      setShippingProfiles(shippingProfiles.sort((sp1, sp2) => sp1.shippingProfileName.localeCompare(
          sp2.shippingProfileName)))
    } else {
      setShippingProfiles(shippingProfiles.sort((sp1, sp2) => sp2.shippingProfileName.localeCompare(
          sp1.shippingProfileName)))
    }
  }

  const onChange = (event) => {
    setSortValue(event.detail)
  }


  if (loading) {
   return (<B2bSpinner data-testid="spinner" size="100" color="primary" />)
  }
  else {
    return (
        <div>
          <div className="profile_description">{t("shipping_profile.description")}</div>
          <B2bAlert data-testid="error-alert" opened = {error} type="error" size="large">{t("error_message")}</B2bAlert>
          {
                <B2bScrollableContainer class="profile_table">
                  <B2bTable size="equal" data-testid="shipping-profiles-table" onB2b-sort-change={onSortChange}>
                    <B2bTableRowgroup type="header">
                      <B2bTableRow>
                        <B2bTableHeader style={{width:"30%", textWrap:"wrap"}} sortDirection = {sortValue} sortId="shippingProfileName" onB2b-change={onChange}>{t(
                            "shipping_profile_list.profile")}</B2bTableHeader>
                        <B2bTableHeader style={{width:"15%", textWrap:"wrap"}}>{t(
                            "shipping_profile_list.shipping_method")}</B2bTableHeader>
                        <B2bTableHeader style={{width:"10%", textWrap:"wrap"}} align="right">{t(
                            "shipping_profile_list.processing_days")}</B2bTableHeader>
                        <B2bTableHeader style={{width:"5%", textWrap:"wrap"}} align="right">{t("shipping_profile_list.cutOff_time")}</B2bTableHeader>
                        <B2bTableHeader style={{width:"12%", textWrap:"wrap"}}>{t("shipping_profile_list.working_days")}</B2bTableHeader>
                        <B2bTableHeader style={{width:"10%", textWrap:"wrap"}} align="right">{t(
                            "shipping_profile_list.transport_days")}</B2bTableHeader>
                        <B2bTableHeader/>
                      </B2bTableRow>
                    </B2bTableRowgroup>
                    {shippingProfiles && shippingProfiles.length > 0 &&
                        <B2bTableRowgroup type="body">
                          {
                            shippingProfiles.map((profile) => (
                                <B2bTableRow highlight="true" color="default"
                                             key={profile.shippingProfileId}>
                                  <B2bTableCell align="left">{profile.shippingProfileName}</B2bTableCell>
                                  <B2bTableCell text-wrap="false" align="left">{t(
                                      "shipping_profile_form.basic_information.shipping_method."
                                      + profile.deliveryType)}</B2bTableCell>
                                  <B2bTableCell align="right">
                                    {profile.defaultProcessingTime}</B2bTableCell>
                                  <B2bTableCell align="right">
                                    {profile.orderCutoff}
                                  </B2bTableCell>
                                  <B2bTableCell align="left">
                                    {parseWorkingDays(profile.workingDays)}
                                  </B2bTableCell>
                                  <B2bTableCell align="right">
                                    {profile.transportTime}</B2bTableCell>
                                  <B2bTableCell style={{textAlign:"center"}}>
                                    <B2bIcon icon="b2b_icon-edit"
                                             color="primary"
                                             size="100"
                                             clickable="true"
                                             data-testid = "update-icon"
                                             onClick={() => handleClick(
                                                 profile)}/>
                                  </B2bTableCell>
                                </B2bTableRow>
                            ))
                          }
                        </B2bTableRowgroup>
                    }
                  </B2bTable>
                  {(shippingProfiles === undefined || shippingProfiles.length === 0) &&
                      <div>
                        <div className="no_profiles_text" data-testid="no_profiles">
                          {t("shipping_profile_list.no_shipping_profiles")}
                        </div>
                        <B2bSeparator/>
                      </div>
                  }
                </B2bScrollableContainer>
          }
          <B2bButton className="create_button"
                     data-testid="create_button"
                     onClick={routeChange}>{t("create_profile")}
          </B2bButton>
          {shippingProfiles && shippingProfiles.length > 0 &&
              <B2bButton className="create_button" data-testid="assign_skus"
                         href="/products/#productoverview">{t("assign_skus")}</B2bButton>
          }
        </div>
    );
  }
}

export default ShippingProfileList;
