import React from 'react';
import './App.css';
import '@otto-de/b2b-core-components/dist/b2b-core-components/b2b-core-components.css';
import {B2bAlert} from '@otto-de/b2b-react-components';
import ShippingProfileList from "./components/ShippingProfileList";
import {useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";

function App() {
  const { t } = useTranslation();
  const { state } = useLocation();

  let created = window.location.hash.split("=")[0].includes("create")
  let updated = window.location.hash.split("=")[0].includes("update")
  let deleted = window.location.hash.split("=")[0].includes("delete")

  const getSuccessNotification = () => {
    return (
        <p>
          {t("success_notification_1")} <b>{state}</b> {t("success_notification_2")}
          <a href={"/products/#productoverview"}>{t("success_notification_link")}</a>
        </p>
    )
  }

  return (
      <div style={ {backgroundColor : "#FFFFFF"}}>
        <B2bAlert opened = {created} type="success" data-testid="create_notification" size="large" has-close-button="true">{getSuccessNotification()}</B2bAlert>
        <B2bAlert opened = {updated} type="success" data-testid="update_notification" size="large" has-close-button="true">{t("update_notification_1")} <b>{state}</b> {t("update_notification_2")}</B2bAlert>
        <B2bAlert opened = {deleted} type="success" size="large" has-close-button="true">{t("delete_notification_1")} <b>{state}</b> {t("delete_notification_2")}</B2bAlert>
        <B2bAlert opened = {true} type="warning" size="large">{t("banner_text")}</B2bAlert>
        <ShippingProfileList/>
      </div>
  );
}

export default App;
