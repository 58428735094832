import constants from "./constants";

const validateTransportTime = (transportTime, setIsTransportTimeValid, setTransportTimeErrorMessage) =>  {
  if (!transportTime) {
    setIsTransportTimeValid(false);
    setTransportTimeErrorMessage(constants.timeIsMandatoryMessage);
  }
  else if (isNaN(transportTime)) {
    setIsTransportTimeValid(false);
    setTransportTimeErrorMessage(constants.timeUnSupportedMessage);
  }
  else {
    let isValid = 0 < transportTime && transportTime <= 99
    setIsTransportTimeValid(isValid);
    setTransportTimeErrorMessage(constants.timeNotInRangeMessage);
  }
}

export const validateProfileName = (shippingProfileName, setIsShippingProfileNameValid, setShippingProfileNameErrorMessage) => {
  if (shippingProfileName) {
    setIsShippingProfileNameValid(shippingProfileName.toString().length <= 100);
    setShippingProfileNameErrorMessage(constants.profileNameLengthExceededMessage);
  }
  else {
    setIsShippingProfileNameValid(false);
    setShippingProfileNameErrorMessage(constants.profileNameIsMandatoryMessage);
  }
}

export const validateCutoffTime = (cutOffTime, setIsCutoffTimeValid, setCutoffTimeErrorMessage) => {
  if(cutOffTime) {
    if (cutOffTime.toString().split(":").length === 2) {
      let hours = cutOffTime.toString().split(":")[0];
      let minutes = cutOffTime.toString().split(":")[1];
      if(hours.length !== 2) {
        setIsCutoffTimeValid(false);
      } else {
        setIsCutoffTimeValid((parseInt(hours) >= 0 && parseInt(hours) <= 23) &&
            (["00", "30"].includes(minutes)));
      }
      setCutoffTimeErrorMessage(constants.cutOffTimeInvalidFormatMessage);
    } else {
      setCutoffTimeErrorMessage(constants.cutOffTimeInvalidFormatMessage);
      setIsCutoffTimeValid(false);
    }
  } else {
    setCutoffTimeErrorMessage(constants.cutOffTimeMandatoryMessage);
    setIsCutoffTimeValid(false);
  }
}

export   const validateDefaultProcessingTime = (defaultProcessingTime, setIsDefaultProcessingTimeValid, setProcessingTimeErrorMessage) =>  {
  if (!defaultProcessingTime) {
    setIsDefaultProcessingTimeValid(false);
    setProcessingTimeErrorMessage(constants.timeIsMandatoryMessage);
  }
  else if (isNaN(defaultProcessingTime)) {
    setIsDefaultProcessingTimeValid(false);
    setProcessingTimeErrorMessage(constants.timeUnSupportedMessage);
  }
  else {
    let isValid = 0 < defaultProcessingTime && defaultProcessingTime <= 99
    setIsDefaultProcessingTimeValid(isValid);
    setProcessingTimeErrorMessage(constants.timeNotInRangeMessage);
  }
}

export default validateTransportTime;
